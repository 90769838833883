<template>
	<div class="wrap">
		<!-- <el-page-header @back="$router.go(-1)" content="商户设置" class="margin-bottom margin-top-xs"></el-page-header> -->
		<div class="form_box">
			<el-card class="box-card">
				<div class="flex justify-between">
					<div class="flex">
						<div>统一销售提成设置：</div>
						<div>
							<el-switch v-model="form.unify" :active-value="1" :inactive-value="0"></el-switch>
						</div>
					</div>
					<div>
						<el-button type="primary" @click="save()">保存</el-button>
					</div>
				</div>
				<div class="margin-top">
					<el-checkbox v-model="form.recharge" :true-label="1" :false-label="0" :disabled="!form.unify">充值销售单独核算</el-checkbox>
					<el-checkbox v-model="form.set_meal" :true-label="1" :false-label="0" :disabled="!form.unify">套餐销售单独核算</el-checkbox>
					<!-- <el-checkbox v-model="form.serveCard" :true-label="1" :false-label="0" :disabled="!form.unify">次卡销售单独核算</el-checkbox> -->
					<el-checkbox v-model="form.goods" :true-label="1" :false-label="0" :disabled="!form.unify">商品销售单独核算</el-checkbox>
					<el-checkbox v-model="form.serve" :true-label="1" :false-label="0" :disabled="!form.unify">服务销售单独核算</el-checkbox>
				</div>
				<div class="flex tips text-sm text-grey">
					<div class="margin-right-sm">
						<i class="el-icon-info"></i>
					</div>
					<div>
						<div>启用状态下：勾选单独核算,则该选项下的商品将不计入统一销售设置,将单独进行设置单独进行核算。</div>
						<div>不启用统一销售提成：则每项商品按照商品自身设置销售提成进行核算。</div>
					</div>
				</div>
			</el-card>
			<div class="flex margin-top flex-wrap">
				<el-card class="arr-card">
					<div>统一销售提成设置</div>
					<el-table :data="unifyArr">
						<el-table-column prop="sale_target" label="销售目标">
							<template slot-scope="scope">
								<el-input class="input" type="number" :disabled="!form.unify" v-model="scope.row.sale_target" placeholder="—"></el-input>
							</template>
						</el-table-column>
						<el-table-column prop="sale_ratio" label="提成比例(%)">
							<template slot-scope="scope">
								<el-input class="input" type="number" :disabled="!form.unify" v-model="scope.row.sale_ratio" placeholder="—"></el-input>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="70">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="clearData(scope.row)" :disabled="!form.unify">清空</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-card>
				<el-card class="arr-card">
					<div>充值销售提成设置</div>
					<el-table :data="rechargeArr">
						<el-table-column prop="sale_target" label="销售目标">
							<template slot-scope="scope">
								<el-input class="input" type="number" :disabled="!form.unify||!form.recharge" v-model="scope.row.sale_target"
								placeholder="—"></el-input>
							</template>
						</el-table-column>
						<el-table-column prop="sale_ratio" label="提成比例(%)">
							<template slot-scope="scope">
								<el-input class="input" type="number" :disabled="!form.unify||!form.recharge" v-model="scope.row.sale_ratio"
								placeholder="—"></el-input>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="70">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="clearData(scope.row)" :disabled="!form.unify||!form.recharge">清空</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-card>
				
				<el-card class="arr-card">
					<div>套餐销售提成设置</div>
					<el-table :data="setMealArr">
						<el-table-column prop="sale_target" label="销售目标">
							<template slot-scope="scope">
								<el-input class="input" type="number" :disabled="!form.unify||!form.set_meal" v-model="scope.row.sale_target"
								placeholder="—"></el-input>
							</template>
						</el-table-column>
						<el-table-column prop="sale_ratio" label="提成比例(%)">
							<template slot-scope="scope">
								<el-input class="input" type="number" :disabled="!form.unify||!form.set_meal" v-model="scope.row.sale_ratio"
								placeholder="—"></el-input>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="70">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="clearData(scope.row)" :disabled="!form.unify||!form.set_meal">清空</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-card>
				<el-card class="arr-card">
					<div>商品销售提成设置</div>
					<el-table :data="goodsArr">
						<el-table-column prop="sale_target" label="销售目标">
							<template slot-scope="scope">
								<el-input class="input" type="number" :disabled="!form.unify||!form.goods" v-model="scope.row.sale_target"
								placeholder="—"></el-input>
							</template>
						</el-table-column>
						<el-table-column prop="sale_ratio" label="提成比例(%)">
							<template slot-scope="scope">
								<el-input class="input" type="number" :disabled="!form.unify||!form.goods" v-model="scope.row.sale_ratio"
								placeholder="—"></el-input>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="70">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="clearData(scope.row)" :disabled="!form.unify||!form.goods">清空</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-card>
				<el-card class="arr-card">
					<div>服务销售提成设置</div>
					<el-table :data="serveArr">
						<el-table-column prop="sale_target" label="销售目标">
							<template slot-scope="scope">
								<el-input class="input" type="number" :disabled="!form.unify||!form.serve" v-model="scope.row.sale_target"
								placeholder="—"></el-input>
							</template>
						</el-table-column>
						<el-table-column prop="sale_ratio" label="提成比例(%)">
							<template slot-scope="scope">
								<el-input class="input" type="number" :disabled="!form.unify||!form.serve" v-model="scope.row.sale_ratio"
								placeholder="—"></el-input>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="70">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="clearData(scope.row)" :disabled="!form.unify||!form.serve">清空</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-card>

			</div>
		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../common/common.rules";
	export default {
		data() {
			return {
				rules,
				form: {
					id: undefined,
					unify: 0, //是否统一设置
					recharge: 0, // 单独核算充值卡
					serveCard: 0, // 单独核算服务卡
					goods: 0, // 单独核算商品
					serve: 0, // 单独核算服务
					set_meal: 0, // 单独核算套餐
					set_item: [
						// {
						// 	id: 13,
						// 	type: 1, 1:统一销售提成 2：充值销售单独核算 3：商品销售单独核算 4：服务销售单独核算 5:服务卡销售单独核算 6：套餐销售单独核算
						// 	sale_target: 1000,
						// 	sale_ratio: 0.0500
						// }
					]
				},
			};
		},
		computed: {
			unifyArr() {
				return this.getArr(1);
			},
			rechargeArr() {
				return this.getArr(2);
			},
			goodsArr() {
				return this.getArr(3);
			},
			serveArr() {
				return this.getArr(4);
			},
			cardArr() {
				return this.getArr(5);
			},
			setMealArr() {
				return this.getArr(7)
			}
		},
		mounted() {
			this.init();
		},
		methods: {
			init(){
				this.$api.store.getSaleRatio().then(res => {
					this.form = res.data
				})
			},
			getArr(type) {
				let arr = this.form.set_item.filter(item => {
					return item.type == type;
				})
				let len = 5 - arr.length;
				for (let i = 0; i < len; i++) {
					arr.push({
						type: type,
						sale_target: '',
						sale_ratio: ''
					})
				}
				return arr;
			},
			clearData(row) {
				row.sale_target = "";
				row.sale_ratio = "";
			},
			save() {
				let arr = this.checkData();
				if (arr) {
					if (arr.length || !this.unify) {
						let data = {
							id: this.form.id,
							unify: this.form.unify, //是否统一设置
							recharge: this.form.recharge, // 单独核算充值卡
							serveCard: this.form.serveCard, // 单独核算服务卡
							goods: this.form.goods, // 单独核算商品
							serve: this.form.serve, // 单独核算服务
							set_meal: this.form.set_meal,// 单独核算套餐
							set_item: arr
						}
						this.$api.store.saveSaleRatio(data).then(res => {
							if (res.code == 200) {
								this.$message.success('保存成功')
								this.init()
							} else {
								this.$message.error(res.msg)
							}
						})
					} else {
						this.$message.warning('请配置提成')
					}
				}
			},
			getArrData(arr) {
				return arr.filter(item => {
					return Boolean(item.sale_target || item.sale_ratio)
				})
			},
			checkData() {
				// 去除禁用的选项
				let unifyArr = this.form.unify ? this.getArrData(this.unifyArr) : [];
				let rechargeArr = this.form.unify && this.form.recharge ? this.getArrData(this.rechargeArr) : [];
				let goodsArr = this.form.unify && this.form.goods ? this.getArrData(this.goodsArr) : [];
				let serveArr = this.form.unify && this.form.serve ? this.getArrData(this.serveArr) : [];
				let cardArr = this.form.unify && this.form.serveCard ? this.getArrData(this.cardArr) : [];
				let setMealArr = this.form.unify && this.form.set_meal ? this.getArrData(this.setMealArr) : [];
				// 拼接数组
				let arr = [
					...unifyArr,
					...rechargeArr,
					...goodsArr,
					...serveArr,
					...cardArr,
					...setMealArr
				]
				let errMsg = '';
				for (let i = 0; i < arr.length; i++) {
					if (arr[i].sale_target < 0) {
						errMsg = '销售目标必须大于等于0'
					}
					if (arr[i].sale_ratio <= 0 || arr[i].sale_ratio > 100) {
						errMsg = '提成比例必须大于0并小于100'
					}
				}
				if (errMsg) {
					this.$message.error(errMsg)
					return false;
				}
				return arr;
			}
		}
	};
</script>
<style scoped lang="scss">
	.form_box {
		// max-width: 600px;
		padding-top: 30px;
	}

	.tips {
		margin-top: 15px;
		line-height: 22px;
	}

	.arr-card {
		width: 340px;
		margin-right: 15px;
		margin-bottom: 15px;

		&:last-child {
			margin-right: 0;
		}

		.el-input>>>input {
			text-align: center;
		}
	}
</style>
